var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.scenario)?[_c('dialog-video',{attrs:{"videoURLS":_vm.videoURLS},model:{value:(_vm.videoDialog),callback:function ($$v) {_vm.videoDialog=$$v},expression:"videoDialog"}}),_c('share-modal',{ref:"shareModalRef",attrs:{"product":_vm.scenario}}),_c('scenario-header',{attrs:{"title":_vm.scenario.title,"level":_vm.scenario.genre ? _vm.scenario.genre.name : '',"rate":_vm.scenario.rate,"routes":[
        { title: 'خانه', url: '/' },
        { title: 'دسته بندی', url: '/categories' },
        { title: 'داستان ها', url: '/scenarios' },
        { title: _vm.scenario.title } ],"date":_vm.scenario.end_date_offer,"img":_vm.scenario.media ? _vm.scenario.media.url : undefined}})]:_vm._e(),_c('v-row',{staticClass:"grey lighten-3 pb-4",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"9","order":"2","order-md":"1"}},[_c('v-card',{staticClass:"rounded-xl ma-2 moholand-shadow"},[_c('v-card-text',{staticClass:"text-justify black--text"},[(_vm.scenario.is_bought && _vm.scenario.full_text_body)?_c('div',{staticClass:"fix-html-images",domProps:{"innerHTML":_vm._s(_vm.scenario.full_text_body)}}):[(_vm.scenario.excerpt)?_c('div',{staticClass:"fix-html-images",domProps:{"innerHTML":_vm._s(_vm.scenario.excerpt)}}):_vm._e(),_c('h4',{staticClass:"orange--text text--darken-2 text-left"},[_vm._v(" برای مشاهده ادامه متن این داستان را خریداری کنید. ")])]],2)],1),_c('comments',{staticClass:"ma-2",attrs:{"inputsGrid":{ cols: 12, sm: 6 },"commentsGrid":{ cols: 12, sm: 6 },"model_id":_vm.scenario.id,"model_slug":_vm.scenario.slug,"comment_type":"scenario"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3","order":"1","order-md":"2"}},[(_vm.scenario && _vm.scenario.user)?_c('v-card',{staticClass:"rounded-xl ma-2 text-center moholand-shadow"},[_c('v-card-text',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6","order-md":"1","order-sm":"2"}},[_c('h2',[_vm._v("فروشنده")]),_c('v-chip',{staticClass:"ma-2 rounded-lg",attrs:{"text-color":"white","label":"","color":"orange darken-1"},on:{"click":function($event){_vm.scenario.user_id
                    ? _vm.$router.push('/sellers/' + _vm.scenario.user.id)
                    : ''}}},[(_vm.scenario.user)?_c('span',[_vm._v(" "+_vm._s(_vm.scenario.user.first_name)+" "+_vm._s(_vm.scenario.user.last_name)+" ")]):_c('span',[_vm._v(" بدون نام")])])],1),_c('v-col',{staticClass:"align-center",attrs:{"cols":"12","md":"6","order-md":"2","order-sm":"1"}},[_c('v-img',{staticClass:"product-img rounded-xl orange darken-1 mx-auto",attrs:{"src":_vm.scenario && _vm.scenario.user
                    ? _vm.scenario.user.avatar_url
                    : require('../../assets/img/man.jpg')}})],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"text-center rounded-xl ma-2 moholand-shadow"},[_c('v-card-text',{staticClass:"black--text"},[(_vm.scenario.end_date_offer && _vm.isValidDiscount)?_c('div',{staticClass:"my-8"},[_c('strike',{staticClass:"mt-5 grey--text"},[_vm._v(" قیمت: "+_vm._s(Intl.NumberFormat().format(_vm.scenario.price))+" تومان ")]),_c('h2',{staticClass:"mt-5 success--text"},[_vm._v(" قیمت با تخفیف: "+_vm._s(Intl.NumberFormat().format(_vm.scenario.offer_price))+" تومان ")])],1):_c('h2',{staticClass:"mt-5 success--text"},[_vm._v(" قیمت: "+_vm._s(Intl.NumberFormat().format(_vm.scenario.price))+" تومان ")]),(
              !_vm.$store.state.userData ||
                _vm.scenario.user_id != _vm.$store.state.userData.id
            )?_c('v-btn',{staticClass:"mt-4 rounded-lg",attrs:{"block":"","x-large":"","color":"success","loading":_vm.loading,"disabled":_vm.scenario.has_bought},on:{"click":_vm.reserve}},[_vm._v(" "+_vm._s(_vm.scenario.has_bought ? "قبلا خریداری شده است." : "همین حالا بخر!")+" ")]):_vm._e()],1)],1)],1)],1),_c('similars',{attrs:{"slug":_vm.slug,"api":"scenarios","color":"orange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var props = ref.props;
return [_c('scenario-item',{attrs:{"scenario":props.item || {}}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }