<template>
  <v-container fluid>
    <v-row :style="'background:' + color">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="2" align-self="center" class="text-center">
            <div class="similar-title text-center white--text">
              شاید خوشتان
              <br />
              بیاید!
            </div>
            <v-btn large outlined color="white" class="mt-3" :to="seeAllUrl">
              مشاهده همه
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" v-if="loading">
            <my-swiper
              :data="Array(4)"
              :maxCount="4"
              hasNavigation
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default>
                <v-skeleton-loader type="card, actions" class="rounded-0" />
              </template>
            </my-swiper>
          </v-col>
          <v-col cols="12" md="10" v-else>
            <my-swiper
              :data="items"
              :maxCount="4"
              hasNavigation
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default="{props}">
                <slot :props="props" />
              </template>
            </my-swiper>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import MySwiper from "../MySwiper.vue";
import MyAxios from "../../constants/MyAxios";
export default {
  components: { MySwiper },
  data() {
    return {
      items: [],
      loading: true,
    };
  },
  mounted() {
    this.GetData();
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
    apiParams: {
      type: Object,
      default: () => ({}),
    },
    seeAllUrl: {
      type: String,
    },
    color: {
      type: String,
      default: "#2684fe",
    },
  },
  methods: {
    GetData: function() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/" + this.api + "/similar/" + this.slug, {
        params: this.apiParams,
      })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  watch: {
    slug() {
      this.GetData();
    },
  },
};
</script>

<style scoped>
.similar-title {
  font-size: 2em;
}
</style>
