<template>
  <v-container fluid class="pa-0">
    <!-- <cart-button /> -->
    <template v-if="scenario">
      <dialog-video v-model="videoDialog" :videoURLS="videoURLS" />
      <share-modal ref="shareModalRef" :product="scenario" />
      <scenario-header
        :title="scenario.title"
        :level="scenario.genre ? scenario.genre.name : ''"
        :rate="scenario.rate"
        :routes="[
          { title: 'خانه', url: '/' },
          { title: 'دسته بندی', url: '/categories' },
          { title: 'داستان ها', url: '/scenarios' },
          { title: scenario.title },
        ]"
        :date="scenario.end_date_offer"
        :img="scenario.media ? scenario.media.url : undefined"
      />
    </template>

    <v-row class="grey lighten-3 pb-4" justify="center" no-gutters>
      <v-col cols="12" md="8" lg="9" order="2" order-md="1">
        <v-card class="rounded-xl ma-2 moholand-shadow">
          <v-card-text class="text-justify black--text">
            <div
              v-if="scenario.is_bought && scenario.full_text_body"
              v-html="scenario.full_text_body"
              class="fix-html-images"
            />
            <template v-else>
              <div
                v-if="scenario.excerpt"
                v-html="scenario.excerpt"
                class="fix-html-images"
              />
              <h4 class="orange--text text--darken-2 text-left">
                برای مشاهده ادامه متن این داستان را خریداری کنید.
              </h4>
            </template>
          </v-card-text>
        </v-card>
        <comments
          :inputsGrid="{ cols: 12, sm: 6 }"
          :commentsGrid="{ cols: 12, sm: 6 }"
          :model_id="scenario.id"
          :model_slug="scenario.slug"
          comment_type="scenario"
          class="ma-2"
        />
      </v-col>
      <v-col cols="12" md="4" lg="3" order="1" order-md="2">
        <v-card
          class="rounded-xl ma-2 text-center moholand-shadow"
          v-if="scenario && scenario.user"
        >
          <v-card-text>
            <v-row class="align-center">
              <v-col cols="12" md="6" order-md="1" order-sm="2">
                <h2>فروشنده</h2>
                <v-chip
                  class="ma-2 rounded-lg"
                  text-color="white"
                  label
                  color="orange darken-1"
                  @click="
                    scenario.user_id
                      ? $router.push('/sellers/' + scenario.user.id)
                      : ''
                  "
                >
                  <span v-if="scenario.user">
                    {{ scenario.user.first_name }} {{ scenario.user.last_name }}
                  </span>
                  <span v-else> بدون نام</span>
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                md="6"
                order-md="2"
                order-sm="1"
                class="align-center"
              >
                <v-img
                  :src="
                    scenario && scenario.user
                      ? scenario.user.avatar_url
                      : require('../../assets/img/man.jpg')
                  "
                  class="product-img rounded-xl orange darken-1 mx-auto"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="text-center rounded-xl ma-2 moholand-shadow">
          <v-card-text class="black--text">
            <div v-if="scenario.end_date_offer && isValidDiscount" class="my-8">
              <strike class="mt-5 grey--text">
                قیمت: {{ Intl.NumberFormat().format(scenario.price) }} تومان
              </strike>
              <h2 class="mt-5 success--text">
                قیمت با تخفیف:
                {{ Intl.NumberFormat().format(scenario.offer_price) }} تومان
              </h2>
            </div>
            <h2 class="mt-5 success--text" v-else>
              قیمت: {{ Intl.NumberFormat().format(scenario.price) }} تومان
            </h2>
            <v-btn
              v-if="
                !$store.state.userData ||
                  scenario.user_id != $store.state.userData.id
              "
              block
              x-large
              color="success"
              class="mt-4 rounded-lg"
              :loading="loading"
              :disabled="scenario.has_bought"
              @click="reserve"
            >
              {{
                scenario.has_bought ? "قبلا خریداری شده است." : "همین حالا بخر!"
              }}
            </v-btn>

            <!-- <div class="d-flex mt-4 justify-center">
              <v-btn
                fab
                v-for="i in 4"
                :key="i"
                class="rounded-lg ma-2"
                color="red"
                dark
              >
                <v-icon large>mdi-instagram</v-icon>
              </v-btn>
            </div> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <similars :slug="slug" api="scenarios" color="orange">
      <template #default="{ props }">
        <scenario-item :scenario="props.item || {}" />
      </template>
    </similars>
  </v-container>
</template>

<script>
import ScenarioItem from "../../components/Pages/ScenarioItem.vue";
import DialogVideo from "../../components/DialogVideo.vue";
import MyClipBoardLink from "../../components/MyClipBoardLink.vue";
import Comments from "../../components/Pages/Comments.vue";
import ScenarioHeader from "../../components/Pages/ScenarioHeader.vue";
import CourseLessons from "../../components/Pages/CourseLessons.vue";
import SimilarCourses from "../../components/Pages/SimilarCourses.vue";
import TitleHeading from "../../components/TitleHeading.vue";
import { Constants } from "../../constants/GlobalConstants";
import MyAxios from "../../constants/MyAxios";
import MyCountdown from "../../components/MyCountdown.vue";
import { getUnixDifferenceTimeFromNow } from "../../constants/TimeFunctions";
import Similars from "../../components/Pages/Similars.vue";
export default {
  components: {
    Comments,
    ScenarioHeader,
    TitleHeading,
    MyClipBoardLink,
    CourseLessons,
    ScenarioItem,
    SimilarCourses,
    DialogVideo,
    MyCountdown,
    Similars,
  },
  data() {
    return {
      mainURL: Constants.mainURL,
      slug: this.$route.params.slug || "",
      origin: window.location.origin,
      scenario: null,
      similar: [],
      videoDialog: false,
      videoURLS: [],
      loading: false,
    };
  },
  created() {
    this.GetData();
  },
  methods: {
    openShareModal() {
      this.$refs.shareModalRef.modal = true;
    },
    GetData: function() {
      this.$root.$emit("globalLoadingSet", true);
      MyAxios.get("/scenarios/index/" + this.slug, {
        params: {
          with: ["genre", "user"],
          noPaginate: true,
          // with: ["chapter", "instructor", "pre_requirements", "video"],
        },
      })
        .then((response) => {
          console.log(response.data[0]);
          this.scenario = response.data[0];
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    playVideo(url) {
      if (url) {
        this.videoURLS = [
          {
            src: url || "",
            // src: item.video.url,
            type: "video/mp4",
          },
        ];
        this.videoDialog = true;
      }
    },
    reserve() {
      if (!this.scenario.has_bought) {
        this.loading = true;
        this.$root.$emit(
          "addToCart",
          this.scenario,
          () => {
            this.loading = false;
          },
          "scenario"
        );
      }
    },
    download(url) {
      window.open(url?.link || url, "_blank");
    },
  },
  watch: {
    "$route.params.slug"(newSlug, oldSlug) {
      this.slug = newSlug;
      this.GetData();
    },
  },
  computed: {
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.scenario.end_date_offer);
      return diff && diff < 0;
    },
  },
};
</script>

<style scoped>
.product-img {
  /* width: 20em;
    height: 20em; */
  border: 5px solid;
  max-height: 150px;
  max-width: 150px;
}
</style>
